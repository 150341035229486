<template>
    <div>
        <Head :title="title" />

        <Banner />

        <nav
            :class="{
                'top-[3.25rem]': page.props.jetstream.flash.banner,
            }"
            class="bg-black border-gray-200 fixed w-full z-[60]"
        >
            <div class="flex flex-wrap items-center justify-between p-4 px-8">
                <Link class="flex items-center space-x-3 rtl:space-x-reverse" href="/">
                    <ApplicationMark class="block h-[5rem] w-auto" />
                </Link>
                <button
                    aria-controls="navbar-default"
                    aria-expanded="false"
                    class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-primary-600 hover:text-black focus:outline-hidden focus:ring-2 focus:ring-gray-200"
                    data-collapse-toggle="navbar-default"
                    type="button"
                    @click="hideMenu = !hideMenu"
                >
                    <span class="sr-only">Open main menu</span>
                    <svg
                        aria-hidden="true"
                        class="w-5 h-5"
                        fill="none"
                        viewBox="0 0 17 14"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 1h15M1 7h15M1 13h15"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                        />
                    </svg>
                </button>
                <div
                    id="navbar-default"
                    :class="{
                        'hidden md:block md:w-auto': hideMenu,
                        'md:block md:w-auto': !hideMenu,
                    }"
                    class="w-full"
                >
                    <ul
                        class="font-medium flex items-center flex-col p-4 md:p-0 mt-4 rounded-lg bg-black md:flex-row gap-4 rtl:space-x-reverse md:mt-0"
                    >
                        <li class="w-full">
                            <Link
                                :href="route('subscriptions.home')"
                                aria-current="page"
                                class="block w-full py-2 px-3 text-gray-100 rounded hover:bg-primary-600 hover:text-black md:hover:bg-transparent md:border-0 md:hover:text-primary-700 md:p-4"
                            >
                                Home
                            </Link>
                        </li>
                        <template v-for="(nav, index) in $page.props.site_config?.primary_navigation?.navigation || []">
                            <li v-if="nav && nav.data" :key="'primary_navigation--' + index" class="w-full">
                                <Link
                                    :href="
                                        route('subscriptions.' + nav.type, {
                                            id: nav.data.id,
                                            _query: {
                                                hide_tags:
                                                    nav?.hide_tags?.length > 0 ? nav?.hide_tags.map((n) => n.id) : null,
                                                only_Tags:
                                                    nav?.only_Tags?.length > 0 ? nav?.only_Tags.map((n) => n.id) : null,
                                            },
                                        })
                                    "
                                    class="block w-full py-2 px-3 text-gray-100 rounded hover:bg-primary-600 hover:text-black md:hover:bg-transparent md:border-0 md:hover:text-primary-700 md:p-4"
                                >
                                    {{ getMenuName(nav) }}
                                </Link>
                            </li>
                        </template>
                        <template v-if="!$page.props.auth.logged_in">
                            <li class="w-full">
                                <Link
                                    :href="route('subscriptions.how-this-works')"
                                    class="block w-full py-2 px-3 text-gray-100 rounded hover:bg-primary-600 hover:text-black md:hover:bg-transparent md:border-0 md:hover:text-primary-700 md:p-4"
                                >
                                    How This Works
                                </Link>
                            </li>
                            <li class="w-full">
                                <Link
                                    :href="route('login')"
                                    class="block w-full py-2 px-3 text-gray-100 rounded hover:bg-primary-600 hover:text-black md:hover:bg-transparent md:border-0 md:hover:text-primary-700 md:p-4"
                                >
                                    Login
                                </Link>
                            </li>
                            <li class="w-full">
                                <Link
                                    :href="route('register')"
                                    class="block w-full py-2 px-3 text-gray-100 rounded hover:bg-primary-600 hover:text-black md:hover:bg-transparent md:border-0 md:hover:text-primary-700 md:p-4"
                                >
                                    Register
                                </Link>
                            </li>
                        </template>
                        <template v-else>
                            <li class="w-full">
                                <Link
                                    :href="route('subscriptions.user.manage.product')"
                                    class="block w-full py-2 px-3 text-gray-100 rounded hover:bg-primary-600 hover:text-black md:hover:bg-transparent md:border-0 md:hover:text-primary-700 md:p-4"
                                >
                                    Manage Subscriptions
                                </Link>
                            </li>
                            <li class="w-full">
                                <Link
                                    :href="route('subscriptions.how-this-works')"
                                    class="block w-full py-2 px-3 text-gray-100 rounded hover:bg-primary-600 hover:text-black md:hover:bg-transparent md:border-0 md:hover:text-primary-700 md:p-4"
                                >
                                    How This Works
                                </Link>
                            </li>
                            <div v-if="$page.props.auth.logged_in" class="hidden md:flex md:items-center md:ms-6">
                                <!-- Settings Dropdown -->
                                <div class="ms-3 relative w-full">
                                    <Dropdown align="right" width="48">
                                        <template #trigger>
                                            <button
                                                v-if="$page.props.jetstream.managesProfilePhotos"
                                                class="flex text-sm border-2 border-transparent rounded-full focus:outline-hidden focus:border-gray-300 transition"
                                            >
                                                <img
                                                    :alt="$page.props.auth.user.name"
                                                    :src="$page.props.auth.user.profile_photo_url"
                                                    class="h-8 w-8 rounded-full object-cover"
                                                />
                                            </button>

                                            <span v-else class="inline-flex w-full rounded-md">
                                                <button
                                                    class="inline-flex items-center w-full px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 bg-white hover:text-gray-700 focus:outline-hidden focus:bg-gray-50 active:bg-gray-50 transition ease-in-out duration-150"
                                                    type="button"
                                                >
                                                    {{ $page.props.auth.user.name }}

                                                    <svg
                                                        class="ms-2 -me-0.5 h-4 w-4"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        stroke-width="1.5"
                                                        viewBox="0 0 24 24"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                    </svg>
                                                </button>
                                            </span>
                                        </template>

                                        <template #content>
                                            <!-- Account Management -->
                                            <div class="block px-4 py-2 text-xs text-gray-400">Manage Account</div>

                                            <DropdownLink :href="route('profile.show')"> Profile </DropdownLink>

                                            <div class="border-t border-gray-200" />

                                            <!-- Authentication -->
                                            <form @submit.prevent="logout">
                                                <DropdownLink as="button"> Log Out </DropdownLink>
                                            </form>
                                        </template>
                                    </Dropdown>
                                </div>
                            </div>
                            <li class="w-full block md:hidden">
                                <Link
                                    :href="route('profile.show')"
                                    aria-current="page"
                                    class="w-full py-2 px-3 text-gray-100 rounded hover:bg-primary-600 hover:text-black md:hover:bg-transparent md:border-0 md:hover:text-primary-700 md:p-4"
                                >
                                    Profile
                                </Link>
                            </li>
                            <li class="w-full block md:hidden">
                                <form @submit.prevent="logout">
                                    <button
                                        aria-current="page"
                                        class="text-left md:hidden w-full py-2 px-3 text-gray-100 rounded hover:bg-primary-600 hover:text-black md:hover:bg-transparent md:border-0 md:hover:text-primary-700 md:p-4"
                                        type="submit"
                                    >
                                        Log out
                                    </button>
                                </form>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </nav>
        <!--pt-40-->
        <main :class="mainPadding + ' ' + addExtraForNavigation()" class="w-full">
            <slot />
        </main>
    </div>
</template>

<script setup>
    import { Head, Link, router, usePage } from "@inertiajs/vue3";
    import { ref } from "vue";
    import ApplicationMark from "../../InertiaComponents/ApplicationMark.vue";
    import Banner from "../../InertiaComponents/Banner.vue";
    import DropdownLink from "../../InertiaComponents/DropdownLink.vue";
    import Dropdown from "../../InertiaComponents/Dropdown.vue";

    const page = usePage();

    const props = defineProps({
        title: {
            default: "Subscriptions",
        },
        smallerTopMargin: {
            required: false,
            default: false,
        },
        mainPadding: {
            default: "p-8",
        },
    });

    const hideMenu = ref(true);

    const getMenuName = (nav) => {
        if (nav.name) {
            return nav.name;
        } else if (nav.data && nav.data.name) {
            return nav.data.name;
        }

        return "";
    };

    const logout = () => {
        router.post(
            route("logout"),
            {},
            {
                onFinish: () => {
                    window.location.reload();
                },
            },
        );
    };

    const addExtraForNavigation = () => {
        if (page.props.jetstream.flash.banner) {
            return "pt-[13rem]";
        }

        if (props.smallerTopMargin) {
            return "pt-[7rem]";
        }

        return "pt-[8rem]";
    };
</script>

<style scoped></style>
